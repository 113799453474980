body {
    margin: 0;
    padding: 0;
}

.grammotes{
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.MuiTabs-flexContainer{
    display: flex;
    flex-wrap: wrap;
}

.MuiToolbar-root{
    gap: 10px;
}

.image_place{
    width: 100vw;
    object-fit: contain;
}

.MuiButtonBase-root{
    z-index: 100;
}

.imageItem img{
    object-fit: cover !important;
    width: min-content !important;
    height: calc(100vh - 100px) !important;
    max-width: 100% !important;
}

.imageItem{
    align-items: center;
}

.Boxing{
    display: flex;
}

.contact{
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}

.phone{
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(232, 232, 232, 0.87);
    text-decoration: none;
    border-bottom: 1px solid rgba(232, 232, 232, 0.87);
}

.phone:hover{
    opacity: 0.8;
}

